
import { defineComponent } from 'vue'
import { EventItem } from '@/types'
import EventService from '@/services/EventService'

export default defineComponent({
  name: 'EventDetail',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      event: {} as EventItem,
    }
  },
  created() {
    EventService.getEvent(this.id)
      .then((response) => {
        this.event = response.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
})
