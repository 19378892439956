
import { defineComponent, PropType } from 'vue'
import { EventItem } from '@/types'

export default defineComponent({
  name: 'EventCard',
  props: {
    event: Object as PropType<EventItem>,
  },
})
