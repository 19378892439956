<template>
  <div>
    <h1>Not Found!</h1>
    <p>
      <a href="/">Go home?</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: red;
  font-size: 2em;
}
</style>
