
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { TodoItem } from '@/types'

export default defineComponent({
  setup() {
    const state = reactive({
      newTask: {
        label: '',
        type: 'personal',
      } as TodoItem,
      taskItems: [] as TodoItem[],
      listFilter: 'all',
    })

    const filteredTasks = computed(() =>{
      if (state.listFilter === 'completed') {
        return state.taskItems.filter((item: TodoItem) => item.isComplete)
      } else if (state.listFilter === 'incomplete') {
        return state.taskItems.filter((item: TodoItem) => !item.isComplete)
      } else {
        return state.taskItems
      }
    })

    const addTask = () => {
      state.taskItems.push({
        ...state.newTask,
        isComplete: false,
      })
    }
    return {
      ...toRefs(state),
      addTask,
      filteredTasks,
    }
  },
  name: 'Todo',
})
